.App__section-container {
  width: 80%;
  height: auto;
  min-height: 200px;
  // border: 1px solid red;
  a {
    color: black;
  }
  a:visited {
    color: black;
  }

  h1 {
    font-size: 2.8em;
    font-family: "Comfortaa", cursive;
    font-weight: 500;
    color: var(--color-blue-3);
    // color: rgb(90, 90, 90);
    position: absolute;
    margin-bottom: 2rem;
    // border: 1px solid red;
    left: 5vw;

    &:after {
      background-image: url(../../project_images/jazz1.webp);
      // background-size: 300px 300px;
      background-size: 20vw 20vw;
      background-position: fill;

      // width: 300px;
      // height: 300px;
      width: 20vw;
      height: 20vw;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      transform: rotateY(180deg) translate(25%, -40%);

      opacity: 0.25;
      z-index: -1;
      content: "";

      display: inline-block;

      // @media (min-width: 2000px) {
      //   background-size: 20vw 20vw;
      //   width: 20vw;
      //   height: 20vw;
      // }
    }
  }

  //phone
  @media (max-width: 1200px) {
    h1 {
      font-size: 10vw;
      margin-bottom: 12vh;
      &:after {
        background-size: 40vh 40vh;
        width: 40vh;
        height: 40vh;
      }
    }
  }

  //tablet
  @media (min-width: 800px) and (max-width: 1200px) {
    h1 {
      font-size: 5vw;
    }
  }
}
