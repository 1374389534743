.App__projects-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 3em;
  margin: 3em 0px;
  padding: 100px 0px 1em 0px;

  .App__projects-card {
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
  }
  .App__projects-card-element {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    width: 100%;
    height: 100%;
  }

  .App__project-card-body {
    background-image: url("../../../../project_images/rising-sun-mountains-starry-sky/1459-sm.webp");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center 90%;
    background-size: 80%;
    color: white;
    .card-text {
      margin-bottom: 2.5em;
    }
  }

  .App__card-btn-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: absolute;
    left: 0;
    bottom: 0.5em;
  }
  .App__project-card-btn {
    // background-color: var(--color-off-white);
    // border: none;
    height: 2.5em;
    width: 6em;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 3000px) {
      padding: 8px;
      font-size: 1em;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 1em;
    margin: 1em 0px;

    .App__projects-card {
      width: 30vw;
      justify-content: center;
      align-items: center;
    }
    .App__project-card-body {
      background-size: cover;
      background-position: bottom;
    }
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    .App__projects-card {
      width: 90%;
      margin-bottom: 1em;
    }
  }
}
