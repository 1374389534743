.App__skills-container {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 2em;
  min-height: 400px;
  padding: 6em 0px 1em 0px;

  .App__skill-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
      width: 6em;
    }
  }

  @media (max-width: 1200px) {
    /* under 350 it should be 1 column, but size change would be preferred */
    grid-template-columns: 1fr 1fr;
    height: auto;
    .App__skill-icon-wrapper {
      img {
        width: 100px;
      }
    }
  }
}
