.App__about-container {
  padding: 50px 0px 1em 0px;
  text-align: center;
  display: grid;
  grid-template-areas:
    ". pic header header"
    "para para para para";

  grid-template-rows: 1fr 1fr;
  grid-template-columns: 0.6fr 1fr 1fr 0.6fr;

  grid-column-gap: 10px;
  // border: 1px solid blue;

  img {
    height: 22vw;
    width: 22vw;
    max-height: 350px;
    max-width: 350px;

    border: 3px solid var(--color-blue-3);
    border-radius: 50%;
    margin: 0px 0px 30px 0px;
    grid-area: pic;
    justify-self: center;
  }

  h3 {
    grid-area: header;
    align-self: center;
    justify-content: left;
    text-align: left;
    font-size: 2em;
    font-family: "Comfortaa", cursive;
  }

  p {
    font-size: 1.4em;
    width: 80%;
    grid-area: para;
    justify-self: center;
  }

  @media (min-width: 3000px) {
    grid-template-areas:
      ". pic header. "
      "para para para para";
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    // img {
    //   height: 30vw;
    //   width: 30vw;
    // }
  }

  @media (max-width: 1200px) {
    grid-template-areas:
      "header"
      "pic"
      "para";
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    padding: 80px 0px 1em 0px;

    h3 {
      font-size: 1.5rem;
      text-align: left;
    }

    img {
      height: 50vw;
      width: 50vw;
      margin-top: 1em;
    }

    p {
      height: 40vh;
      width: 100%;
      font-size: 5vw;
      overflow: auto;
    }
  }
}
