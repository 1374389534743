.App__nav-backdrop-container {
  .App__mobile-menu-backdrop {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(30, 30, 30, 0.5);
    backdrop-filter: blur(5px);
    z-index: 5;
  }
}
.App__nav-container {
  width: 100%;
  height: 5em;
  min-height: 120px;

  background: rgba(250, 250, 250, 0.25);
  backdrop-filter: blur(4px);
  color: var(--color-blue-3);
  font-size: 1.5em;
  text-align: left;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5em;

  z-index: 20;

  /* MOBILE NAV MENU STYLES */

  @media (max-width: 1200px) {
    .App__nav-list {
      display: none;
    }

    .App__mobile-menu-container {
      height: 40vh;
      width: 50vw;
      background: rgb(240, 240, 240);
      // background: var(--color-blue-5);
      position: fixed;
      right: 0;
      top: 0;
      transform: translateX(80vw);
      transition: 200ms ease-out;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      // padding-bottom: 0.5em;
      z-index: 20;

      .App__nav-list-mobile {
        height: 80%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: right;
        z-index: 5;
        list-style: none;
        // border: 1px solid blue;
        text-transform: uppercase;
      }
      .App__nav-btn-mobile {
        height: 60%;
        // width: %;
        padding-right: 10px;
        display: flex;
        align-items: flex-end;
        text-align: right;
        justify-content: right;
        font-family: "Comfortaa", cursive;
        font-weight: 700;
        // color: var(--color-blue-3);
        color: var(--color-blue-3);
        border-bottom: 1px solid var(--color-blue-3);
        &:first-child {
          border-top: 2px solid var(--color-blue-3);
        }
      }
      .light {
        // background: rgb(210, 210, 210);
        // background: var(--color-blue-4);
        background: rgb(240, 240, 240);
      }
      .dark {
        background: rgb(240, 240, 240);

        // background: var(--color-blue-5);
        // background: var(--color-purple-5);
      }
    }

    .App__nav-name-logo {
      font-size: 1.6em;
    }

    .App__mobile-nav-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .active {
      transform: translateX(0vw);
    }
  }

  /* DESKTOP NAV MENU STYLES */

  @media (min-width: 1201px) {
    .App__nav-mobile-container {
      display: none;
    }

    .App__nav-list {
      width: 60%;
      height: 100%;

      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      text-decoration: none;
      list-style: none;

      text-transform: uppercase;

      .App__nav-btn {
        border-radius: 6px;
        background: var(--color-blue-3);
        transition: 200ms ease-in-out;
        border: 2px solid rgba(0, 0, 0, 0);
        padding: 0px 5px;
        &:hover {
          transition: 200ms ease-out;
          background: var(--color-off-white);
          border: 2px solid var(--color-blue-3);
        }
      }

      a {
        min-width: 120px;
        min-height: 50px;
        width: 5em;
        height: 2em;

        text-decoration: none;
        font-weight: 500;
        color: var(--color-off-white);
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: 200ms ease-in-out;

        &:visited {
          color: var(--color-off-white);
        }

        &:hover,
        &:visited:hover {
          color: var(--color-blue-3);
        }
      }
    }
  }
}
.App__nav-name-logo {
  font-family: "Comfortaa", cursive;
  font-weight: 700;
  font-size: 1.8em;
}

.hidden {
  display: none;
}
