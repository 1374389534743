:root {
  --color-blue-1: rgb(13, 27, 42);
  --color-blue-2: rgb(27, 38, 59);
  --color-blue-3: rgb(65, 90, 119);
  --color-blue-4: rgb(119, 141, 169);
  --color-blue-5: rgb(150, 170, 200);
  --color-purple-5: rgb(185, 159, 194);
  --color-off-white: rgb(220, 220, 220);
  --color-gray: rgba(180, 180, 180, 0.9);
  --color-white-transparent: rgba(250, 250, 250, 0.8);
}

html * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
a:visited {
  color: black;
  text-decoration: none;
}
a:active,
a:hover {
  text-decoration: underline;
}
.App {
  /* text-align: center; */
  font-size: 16px;
  /* background: var(--color-off-white); */
  @media (min-width: 3000px) {
    font-size: 32px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

section {
  scroll-margin-top: 8em;
  margin: 2em 0px;
  &:first-child {
    margin-top: 5em;
  }
}

section:last-child {
  min-height: 0px;
  height: auto;
}

.App-link {
  color: #61dafb;
}

footer {
  width: 100vw;
  height: auto;
  background: black;
  color: gray;

  a {
    color: gray;
  }
  a:active,
  a:hover {
    color: gray;
  }
  a:visited {
    color: gray;
  }

  .App__footer-container {
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .App__citation-wrapper {
      margin-left: 1em;
      @media (max-width: 1200px) {
        font-size: 0.8em;
        margin-left: 5px;
      }
    }
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
